import { useNavigate } from "react-router-dom";

const imageUrl = {
  DesktopHero: `${process.env.REACT_APP_CDN_BASEURL}/DesktopHero.jpg`,
  MobileHero: `${process.env.REACT_APP_CDN_BASEURL}/MobileHero.png`,
  CTA: `${process.env.REACT_APP_CDN_BASEURL}/cta.png`,
  ProductCard: `${process.env.REACT_APP_CDN_BASEURL}/Background%2BBorder.png`,
  ProductCardMobile: `${process.env.REACT_APP_CDN_BASEURL}/Background%2BBorder-sm.png`,
};

const DemoPage = () => {
  const navigate = useNavigate();

  const handleClick = (url) => {
    navigate("/add-line", {
      state: { url },
    });
  };

  return (
    <div className="lg:px-20 bg-black">
      <div className="xs:hidden lg:block">
        <div id="desktop-01">
          <img src={imageUrl.DesktopHero} alt="" />
        </div>
        <div id="desktop-02">
          <img src={imageUrl.DesktopHero} alt="" />
        </div>
        <div id="desktop-03">
          <img src={imageUrl.DesktopHero} alt="" />
        </div>
        <div
          id="desktop-04-cta-1"
          className="xs:hidden lg:block lg:h-[300px] xl:h-[350px] 2xl:h-[400px] py-10"
        >
          <img
            className="object-cover mx-auto h-full"
            src={imageUrl.CTA}
            alt=""
          />
        </div>
        <div id="desktop-05">
          <img src={imageUrl.DesktopHero} alt="" />
        </div>
        <div id="desktop-06">
          <img src={imageUrl.DesktopHero} alt="" />
        </div>
      </div>

      <div className="xs:hidden lg:flex lg:justify-between">
        <div
          id="desktop-07-sku-1"
          className="w-full"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/Rdlnot")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="desktop-07-sku-2"
          className="w-full"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/vauEUo")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="desktop-07-sku-3"
          className="w-full"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/Rdlnot")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="desktop-07-sku-4"
          className="w-full"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/vauEUo")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
      </div>

      <div className="xs:block lg:hidden">
        <div id="mobile-01">
          <img src={imageUrl.MobileHero} alt="" />
        </div>
        <div id="mobile-02">
          <img src={imageUrl.MobileHero} alt="" />
        </div>
        <div id="mobile-03">
          <img src={imageUrl.MobileHero} alt="" />
        </div>
        <div
          id="mobile-04-cta-01"
          className="xs:h-[200px] sm:h-[220px] md:h-[250px] lg:hidden py-10"
        >
          <img
            className="object-cover mx-auto h-full"
            src={imageUrl.CTA}
            alt=""
          />
        </div>
        <div id="mobile-05">
          <img src={imageUrl.MobileHero} alt="" />
        </div>
        <div id="mobile-06">
          <img src={imageUrl.MobileHero} alt="" />
        </div>
      </div>

      <div className="xs:flex lg:hidden xs:justify-between w-full flex-wrap">
        <div
          id="mobile-07-sku-1"
          className="w-1/2"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/Rdlnot")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="mobile-07-sku-2"
          className="w-1/2"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/vauEUo")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="mobile-07-sku-3"
          className="w-1/2"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/Rdlnot")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
        <div
          id="mobile-07-sku-4"
          className="w-1/2"
          onClick={() =>
            handleClick("https://ccore.newebpay.com/EPG/wucareer/vauEUo")
          }
        >
          <img className="w-full" src={imageUrl.ProductCard} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DemoPage;
