import { useLocation } from "react-router-dom";

const AddLine = () => {
  const location = useLocation();
  const { url } = location.state || {}; // 取得傳入的參數

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col gap-5 text-center">
        <div>購買須知</div>
        <div>在購買前請記得加入 WuCareer 的官方賴</div>
        <div>
          再購買完成後，將購買完成證明傳給客服，我們將在一個工作天內幫您開課
        </div>
        <div className="w-[200px] h-[200px] bg-slate-200 mx-auto">qr code</div>
        <div>
          <a
            className={`bg-red-300 p-3 rounded-md`}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            我已了解，帶我到結帳頁面
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddLine;
