import { createBrowserRouter } from "react-router-dom";
import DemoPage from "../pages/demoPage";
import AddLine from "../pages/addLine";

const router = createBrowserRouter([
  {
    path: "/",
    element: <DemoPage />,
  },
  {
    path: "/add-line",
    element: <AddLine />,
  },
]);

export default router;
